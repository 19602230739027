export async function getRank(arms){
    let S=0;
    let A=0;
    let B=0;
    let C=0;
    let D=0;
    arms.forEach(element => {
        let quality=Number(element.quality.toNumber());
        if(quality==1){
            D=D+1;
        }else if(quality==2){
            C=C+1;
        }else if(quality==3){
            B=B+1;
        }else if(quality==4){
            A=A+1;
        }else if(quality==5){
            S=S+1;
        }
    });
   
    let contrast =
    [
       
        {
            "name":"D",
            "val":D
        },
         {
            "name":"C",
            "val":C
        },
        {
            "name":"B",
            "val":B
        },
        {
            "name":"A",
            "val":A
        },
        {
            "name":"S",
            "val":S
        },
        
       
    ];
    
    let arrs = contrast.map((value, index, array) => {
        return value.val
    })
    let nowMax=Math.max(...arrs);
   
    let maxRank=contrast.find((value,index,arr)=>{
        if(value.val==nowMax){
            return value;
        }
        
    });
 
    return maxRank.name;
   
    
}


export async function getPower(minePower,gemArr){
  
    let newAddPower=0;
    gemArr.map((value, index, array) => {
        newAddPower=newAddPower+value.aggressivity.toNumber()
        return newAddPower;
    })
   
    return Number(newAddPower)+Number(minePower);
   
    
}

export async function sortArr(arrs,type){
    console.log("传过来得数组工具",arrs)
    let newArr=[];
    for (let i = 0; i < arrs.length; i++) {

        newArr[i] = arrs[i]
    }
    //武器
	if(type==0){
        newArr.sort(function (a, b) { return b.quality - a.quality; }) ;
        return newArr;
    }
    //宝石
    if(type==1){
        newArr.sort(function (a, b) { return b.aggressivity - a.aggressivity; }) ;
        return newArr;
    }
}